import React from 'react'
import './companyInterviewBlock.scss'
import { PopupLoader } from '../../../../organism'
import emptyStar from '../../../../../assets/icons/active-icons/empty_yellow_star.svg'
import filledStar from '../../../../../assets/icons/active-icons/filled_yellow_star.svg'
import { CompanyInterviewDetails } from '../../../../../models'
import { Button } from '../../../../atoms'
import { IRatingInterview } from './CompanyInterviewBlock'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  isSaving: boolean
  companyName?: string
  rating: IRatingInterview
  interviewDetails?: CompanyInterviewDetails | null
  setRating: React.Dispatch<React.SetStateAction<IRatingInterview>>
  isEdited: boolean
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>
  getColor: () => 'red' | 'yellow' | 'green'
  onEdit: () => void
  onSave: () => void
  onSetRating: (index: number) => void
}

export const CompanyInterviewBlockUI = (props: Props) => {
  const {
    isSaving,
    companyName,
    rating,
    interviewDetails,
    setRating,
    isEdited,
    setIsEdited,
    getColor,
    onEdit,
    onSave,
    onSetRating,
  } = props

  return (
    <div className="company-interview">
      {isSaving && <PopupLoader>Saving...</PopupLoader>}
      <div className="company-interview__stars-section">
        <div className="company-interview__stars-description">
          <h4 className="company-interview__stars-title">
            {companyName ?? 'Company'} Interview
          </h4>

          <p className="company-interview__stars-text">
            Rate your interview and add notes.
          </p>
        </div>
        <div className="company-interview__stars">
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <img
                key={uuidv4()}
                src={
                  rating.overall_score >= (index + 1) * 20
                    ? filledStar
                    : emptyStar
                }
                alt="rating star"
                className={interviewDetails ? '' : 'company-interview__star'}
                onClick={() => onSetRating(index)}
              />
            ))}
        </div>
      </div>
      <div className="company-interview__notes-section">
        <div className="company-interview__notes-block">
          <h4 className="company-interview__notes-title">Interview Notes</h4>
          {interviewDetails && (
            <strong onClick={() => setIsEdited(false)}>Edit</strong>
          )}
        </div>

        {isEdited ? (
          <p className="company-interview__note">{interviewDetails?.notes}</p>
        ) : (
          <>
            <textarea
              placeholder="Enter any notes that may be relevant to the interview..."
              className={`company-interview__notes-textarea ${getColor()}`}
              value={rating.notes}
              onChange={e =>
                setRating(prevRating => ({
                  ...prevRating,
                  notes: e.target.value,
                }))
              }
            />
            <Button
              className="company-interview__btn"
              onClick={() => {
                if (interviewDetails) {
                  onEdit()
                  setIsEdited(true)
                } else {
                  onSave()
                }
              }}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
