import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react'
import * as route from '../../../services/route'
import { useHistory } from 'react-router-dom'
import { IWorkerInfoOB, TWorkExperience } from '../../../models'
import { OBLastJobsUI } from './OBLastJobsUI'
import { EDate } from '../../../utils/constants/obConstants'
import { setWorkerInfo, useAppDispatch } from '../../../redux'
import {
  useTypedSelector,
  usePageNumUpdateOB,
  useMediaContext,
  useStateWithDep,
} from '../../../utils/hooks'
import { LastJobsContext } from '../../../components/Context'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  clearIncompleteExperience,
  updateCustomJobs,
  updatePublicInfo,
} from '../../../services/workerApi'
import {
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import { toast } from 'react-toastify'
import {
  getUserInfoFromStorage,
  onTrackingActions,
} from '../../../utils/scripts'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'
import {
  TIsFormOpen,
  TIsFormOpenToAdd,
} from '../../../components/organism/Worker/OBcomponents/BestJobsOB/AddJobForm/AddJobForm'

export enum EJobFields {
  company_name = 'company_name',
  job_title = 'job_title',
  description = 'description',
  location = 'location',
  start_date = 'start_date',
  end_date = 'end_date',
}

export type TEditIncompleteExp = {
  index: number
  fields: EJobFields[]
}

export type TCombinedFormType =
  | React.Dispatch<React.SetStateAction<TIsFormOpenToAdd>>
  | React.Dispatch<React.SetStateAction<TIsFormOpen>>

const sortJobs = (jobA: TWorkExperience, jobB: TWorkExperience) => {
  if (!jobA.end_date || !jobB.end_date) return -1
  if (jobA.end_date === EDate.present || jobB.end_date === EDate.present)
    return 1
  return new Date(jobB.end_date).getTime() - new Date(jobA.end_date).getTime()
}

export const OBLastJobs = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const lastPage = userInfo.last_page < 9 ? 9 : userInfo.last_page
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const pageStateOBMutation = usePageNumUpdateOB()
  const isMobile = useMediaContext()
  const userInfoFromStoreAndQuery = useRef<IWorkerInfoOB | null>(
    getUserInfoFromStorage()
  )
  const [numJobOfInvitee, setNumJobOfInvitee] = useState(0)
  const [isPromptOpen, setIsPromptOpen] = useState(false)
  const [isClickCloseBtn, setIsClickCloseBtn] = useState(false)
  const hasIncompleteExperience = useRef<boolean | null>(
    JSON.parse(
      `${sessionStorage.getItem(EWorkerSStoreKeys.obIncompleteExperience)}`
    )
  )
  const isInvitedWorker = useRef<boolean | null>(
    JSON.parse(`${sessionStorage.getItem(EWorkerSStoreKeys.obIsInvited)}`)
  )

  const { refetch: clearIncompleteRefresh } = useQuery(
    WorkerQueries.clearIncompleteExperience,
    () => clearIncompleteExperience(),
    {
      enabled: false,
    }
  )

  const { refetch: obCustomJobsUpdate } = useQuery(
    WorkerQueries.customJobsOB,
    () => updateCustomJobs(),
    {
      onSuccess() {
        const getNewData = (data: IWorkerInfoOB) => ({
          ...data,
          user_data: {
            ...data.user_data,
            incomplete_work_experience: [],
            work_experience: [],
            custom_jobs: false,
          },
        })
        sessionStorage.setItem(
          EWorkerSStoreKeys.obData,
          JSON.stringify(getNewData(getUserInfoFromStorage() ?? userInfo))
        )
        queryClient.setQueryData(
          WorkerQueries.workerInfoOB,
          getNewData(userInfo)
        )
      },
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
      enabled: false,
    }
  )

  const getJobList = useMemo((): TWorkExperience[] => {
    if (!userInfoFromStoreAndQuery.current) return []

    const allJobs = [
      ...userInfoFromStoreAndQuery.current.user_data.incomplete_work_experience,
      ...userInfoFromStoreAndQuery.current.user_data.work_experience,
    ]

    return allJobs.sort(sortJobs)
  }, [])

  const [jobs, setJobs] = useStateWithDep<TWorkExperience[]>(getJobList)

  const [jobEditModal, setJobEditModal] = useState<false | TEditIncompleteExp>(
    false
  )

  const publicInfoMutation = useMutation(
    (data: { work_experience: TWorkExperience[] }) => updatePublicInfo(data),
    {
      onError(err) {
        if (err instanceof Error) toast.error(err.message)
      },
    }
  )

  const updateWorkExperience = useCallback(
    (data: IWorkerInfoOB) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data.user_data,
        work_experience: jobs,
        incomplete_work_experience: [],
        custom_jobs: data.user_data.custom_jobs,
      },
    }),
    [jobs, lastPage, lastPageState.last_location]
  )

  const checkJobFields = useCallback(
    (
      jobsList: TWorkExperience[],
      getModal: boolean = false
    ): boolean | TEditIncompleteExp => {
      let isError = false
      let incompleteJobs: TEditIncompleteExp | null = null

      const checkField = (
        input: string | number | boolean | null | undefined,
        field: keyof typeof EJobFields,
        index: number
      ) => {
        const errHandler = (fieldName: EJobFields) => {
          if (!input && fieldName !== EJobFields.description) {
            if (getModal) {
              if (incompleteJobs) {
                incompleteJobs.fields.push(fieldName)
              } else {
                incompleteJobs = {
                  index: index,
                  fields: [fieldName],
                }
              }
            } else {
              toast.error(
                `"${fieldName}" field in job No. ${index + 1} is empty`
              )
            }
            isError = true
          }
        }
        errHandler(EJobFields[field])
      }

      for (let i = 0; i < jobsList.length; i++) {
        const job = jobsList[i]
        let key: keyof typeof EJobFields
        for (key in EJobFields) {
          checkField(job[key], key, i)
        }
        if (getModal && incompleteJobs) return incompleteJobs
      }

      return isError
    },
    []
  )

  const onCloseModal = (value: boolean, setIsFormOpen: TCombinedFormType) => {
    if (!value) {
      setIsFormOpen(false)
      return
    }
    setIsPromptOpen(true)
  }

  const onClickBack = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(updateWorkExperience(getUserInfoFromStorage() ?? userInfo))
    )
    isMobile || userInfo.user_data.custom_jobs
      ? history.push(route.OBPath.onboardingLocation)
      : history.push(route.OBPath.onboardingUploadResume)
  }, [history, isMobile, updateWorkExperience, userInfo])

  const onClickNext = useCallback(async () => {
    if (checkJobFields(jobs)) return

    await onTrackingActions(OBAnalyticsName.work_experience_submitted)

    publicInfoMutation.mutate(
      { work_experience: jobs },
      {
        onSuccess() {
          pageStateOBMutation.mutate(lastPageState, {
            onSuccess() {
              queryClient.setQueryData(
                WorkerQueries.workerInfoOB,
                updateWorkExperience(userInfo)
              )
              dispatch(setWorkerInfo(updateWorkExperience(userInfo)))
              sessionStorage.setItem(
                EWorkerSStoreKeys.obData,
                JSON.stringify(
                  updateWorkExperience(getUserInfoFromStorage() ?? userInfo)
                )
              )
              clearIncompleteRefresh()
              sessionStorage.removeItem(
                EWorkerSStoreKeys.obIncompleteExperience
              )
              history.push(route.OBPath.onboardingLanguages)
            },
          })
          sessionStorage.removeItem(EWorkerSStoreKeys.obIsInvited)
        },
      }
    )
  }, [
    history,
    dispatch,
    pageStateOBMutation,
    publicInfoMutation,
    queryClient,
    jobs,
    checkJobFields,
    updateWorkExperience,
    clearIncompleteRefresh,
    userInfo,
    lastPageState,
  ])

  useEffect(() => {
    if (jobs && !jobs?.length && getJobList.length !== jobs?.length) {
      if (userInfoFromStoreAndQuery.current) {
        userInfoFromStoreAndQuery.current.user_data.custom_jobs = false
      }
      obCustomJobsUpdate()
    }
  }, [obCustomJobsUpdate, getJobList.length, jobs])

  useEffect(() => {
    if (!userInfoFromStoreAndQuery.current) {
      userInfoFromStoreAndQuery.current = userInfo
    }
  }, [userInfo])

  useEffect(() => {
    if (hasIncompleteExperience.current) {
      const modal = checkJobFields(jobs, true)

      if (typeof modal === 'object') {
        setJobEditModal(modal)
      } else {
        sessionStorage.setItem(
          EWorkerSStoreKeys.obIncompleteExperience,
          JSON.stringify('false')
        )
        hasIncompleteExperience.current = false
      }
    }
  }, [checkJobFields, jobs])

  useEffect(() => {
    if (isInvitedWorker.current) {
      if (jobs.length && numJobOfInvitee < jobs.length) {
        const incompleteFields: EJobFields[] = []
        const jobData = Object.entries(jobs[numJobOfInvitee])
          .filter(el => !el[1])
          .map(el => el[0])

        let key: keyof typeof EJobFields
        for (key in EJobFields) {
          jobData.includes(key) && incompleteFields.push(EJobFields[key])
        }
        setJobEditModal({ index: numJobOfInvitee, fields: incompleteFields })
        setNumJobOfInvitee(prev => prev + 1)
      } else {
        sessionStorage.setItem(
          EWorkerSStoreKeys.obIsInvited,
          JSON.stringify('false')
        )
        isInvitedWorker.current = false
      }
    }
    // eslint-disable-next-line
  }, [jobs]) ///TODO not use eslint disable

  return (
    <LastJobsContext.Provider
      value={{
        userInfo: userInfo,
        jobs: jobs,
        setJobs: setJobs,
      }}
    >
      <OBLastJobsUI
        onClickBack={onClickBack}
        onClickNext={onClickNext}
        jobEditModal={jobEditModal}
        setJobEditModal={setJobEditModal}
        isUploadedResume={
          userInfoFromStoreAndQuery.current?.user_data.custom_jobs ?? false
        }
        isWaitingResponse={
          publicInfoMutation.isIdle ? false : !publicInfoMutation.error
        }
        jobs={jobs}
        isPromptOpen={isPromptOpen}
        setIsPromptOpen={setIsPromptOpen}
        isClickCloseBtn={isClickCloseBtn}
        setIsClickCloseBtn={setIsClickCloseBtn}
        onCloseModal={onCloseModal}
      />
    </LastJobsContext.Provider>
  )
}
