import { useHistory } from 'react-router-dom'
import { OBRatesUI } from './OBRatesUI'
import * as route from '../../../services/route'
import { useCallback, useMemo, useState } from 'react'
import { usePageNumUpdateOB, useTypedSelector } from '../../../utils/hooks'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  appliedOnboarding,
  updateWorkPreferencesInfo,
} from '../../../services/workerApi'
import { toast } from 'react-toastify'
import {
  ECompletionStatusOB,
  EWorkerSStoreKeys,
  OBAnalyticsName,
  WorkerQueries,
} from '../../../utils/constants'
import { setStatus, setWorkerInfo, useAppDispatch } from '../../../redux'
import {
  getUserInfoFromStorage,
  onTrackingActions,
} from '../../../utils/scripts'
import { IWorkerInfoOB } from '../../../models'
import { getLastPageState } from '../../../utils/hooks/obHooks/usePageNumUpdateOB'

export interface IHourlyPayInfoOB {
  most_recent_pay_rate: string
  desired_pay_rate: string
}

export const OBRates = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const pageStateOBMutation = usePageNumUpdateOB()
  const userInfo = useTypedSelector(s => s.obInfo.workerInfo)
  const lastPage = userInfo.last_page < 14 ? 14 : userInfo.last_page
  const userInfoFromStorage = useMemo(() => getUserInfoFromStorage(), [])
  const lastPageState = useMemo(() => getLastPageState(lastPage), [lastPage])
  const obStatus: ECompletionStatusOB | null = useTypedSelector(
    s => s.auth.obStatus
  )

  const [hourlyPayInfo, setHourlyPayInfo] = useState<IHourlyPayInfoOB>({
    most_recent_pay_rate: userInfoFromStorage?.user_data?.most_recent_pay_rate
      ? userInfoFromStorage.user_data?.most_recent_pay_rate
      : userInfo?.user_data?.most_recent_pay_rate ?? '0',
    desired_pay_rate: userInfoFromStorage?.user_data.desired_pay_rate
      ? userInfoFromStorage.user_data.desired_pay_rate
      : userInfo?.user_data?.desired_pay_rate ?? '0',
  })

  const isDataChanged =
    userInfo?.user_data?.most_recent_pay_rate !==
      hourlyPayInfo?.most_recent_pay_rate ||
    userInfo?.user_data?.desired_pay_rate !== hourlyPayInfo?.desired_pay_rate

  const obRatesInfoMutation = useMutation((data: IHourlyPayInfoOB) =>
    updateWorkPreferencesInfo(data)
  )

  const { refetch: obAppliedRefresh } = useQuery(
    WorkerQueries.appliedOB,
    () => appliedOnboarding(),
    {
      onSuccess: () => {
        dispatch(setStatus(ECompletionStatusOB.applied))
        onTrackingActions(OBAnalyticsName.ob_app_complete)
        history.push(route.OBPath.onboardingReferences)
      },
      onError: err => {
        if (err instanceof Error) toast.error(err.message)
      },
      enabled: false,
    }
  )

  const isNextButtonDisabled =
    hourlyPayInfo.desired_pay_rate !== '0' &&
    hourlyPayInfo.most_recent_pay_rate !== '0' &&
    obRatesInfoMutation.isIdle
      ? false
      : !obRatesInfoMutation.error

  const hourlyPayHandler = useCallback(
    (number: number, key: string) => {
      setHourlyPayInfo({
        ...hourlyPayInfo,
        [key]: number.toString(),
      })
    },
    [hourlyPayInfo]
  )

  const getNewData = useCallback(
    (data: IWorkerInfoOB) => ({
      ...data,
      last_page: lastPage,
      last_location: lastPageState.last_location,
      user_data: {
        ...data?.user_data,
        most_recent_pay_rate: hourlyPayInfo.most_recent_pay_rate,
        desired_pay_rate: hourlyPayInfo.desired_pay_rate,
      },
    }),
    [
      hourlyPayInfo.most_recent_pay_rate,
      hourlyPayInfo.desired_pay_rate,
      lastPage,
      lastPageState.last_location,
    ]
  )

  const updateStatus = useCallback(() => {
    obStatus === ECompletionStatusOB.applied
      ? history.push(route.OBPath.onboardingReferences)
      : obAppliedRefresh()
  }, [history, obAppliedRefresh, obStatus])

  const onClickNext = useCallback(async () => {
    if (isDataChanged) {
      await onTrackingActions(OBAnalyticsName.pay_rates_submitted)
      obRatesInfoMutation.mutate(hourlyPayInfo, {
        onSuccess() {
          pageStateOBMutation.mutate(lastPageState, {
            onSuccess() {
              queryClient.setQueryData(
                WorkerQueries.workerInfoOB,
                getNewData(userInfoFromStorage ?? userInfo)
              )
              sessionStorage.setItem(
                EWorkerSStoreKeys.obData,
                JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
              )
              dispatch(setWorkerInfo(getNewData(userInfo)))
              updateStatus()
            },
            onError: err => {
              if (err instanceof Error) toast.error(err.message)
            },
          })
        },

        onError: err => {
          if (err instanceof Error) toast.error(err.message)
        },
      })
    } else {
      updateStatus()
    }
  }, [
    isDataChanged,
    getNewData,
    dispatch,
    userInfo,
    userInfoFromStorage,
    obRatesInfoMutation,
    pageStateOBMutation,
    hourlyPayInfo,
    queryClient,
    lastPageState,
    updateStatus,
  ])

  const onClickBack = useCallback(() => {
    sessionStorage.setItem(
      EWorkerSStoreKeys.obData,
      JSON.stringify(getNewData(userInfoFromStorage ?? userInfo))
    )

    history.push(route.OBPath.onboardingWillingToRelocate)
  }, [history, userInfoFromStorage, getNewData, userInfo])

  return (
    <OBRatesUI
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      hourlyPay={hourlyPayInfo}
      hourlyPayHandler={hourlyPayHandler}
      isNextButtonDisabled={isNextButtonDisabled}
    />
  )
}
