import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../../utils/hooks'
import {
  getAssessmentForms,
  getAssessmentResults,
  getBasicInfo,
  getPublicInfo,
} from '../../../../services/workerApi'
import { useQuery, useQueryClient } from 'react-query'
import { WorkerQueries } from '../../../../utils/constants'
import { DashboardUI } from './DashboardUI'
import { QueryState } from 'react-query/types/core/query'
import { BasicInfoModel, CareerGuideInfo, PublicInfo } from '../../../../models'
import moment from 'moment'
import 'moment-timezone'

export const Dashboard = () => {
  const user = useTypedSelector(s => s.auth.user)
  const queryClient = useQueryClient()
  const userUuid = user?.uuid ?? ''
  const careerGuideInfoFromServer =
    user?.progress.career_guide_call.additional_info
  const isProfileCompleted = !!user?.progress.complete_profile.is_complete
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneAbr = moment.tz(userTimeZone).zoneAbbr()

  const [careerGuideInfo, setCareerGuideInfo] =
    useState<CareerGuideInfo | null>(careerGuideInfoFromServer ?? null)

  const [isAssessModalVisible, setIsAssessModalVisible] = useState(false)
  const [isCareerGuideModalVisible, setIsCareerGuideModalVisible] =
    useState(false)

  const { data: assessments, isSuccess: isAssessmentsSuccess } = useQuery(
    WorkerQueries.assessmentResults,
    () => getAssessmentResults()
  )

  const { data: assessmentAvailableForms } = useQuery(
    [WorkerQueries.availableForms, false, userUuid, true],
    () => getAssessmentForms(false, userUuid, true)
  )

  const publicInfoQuery: QueryState<PublicInfo, undefined> | undefined =
    queryClient.getQueryState(WorkerQueries.publicInfo)

  const { data: publicInfo } = useQuery(
    WorkerQueries.publicInfo,
    () => getPublicInfo(),
    {
      enabled: !publicInfoQuery?.data,
    }
  )
  const basicInfoQuery = queryClient.getQueryState(WorkerQueries.basicInfo)

  const { data: basicInfo } = useQuery<BasicInfoModel>(
    WorkerQueries.basicInfo,
    () => getBasicInfo(),
    {
      enabled: !basicInfoQuery?.data,
    }
  )

  const profileToggler = () =>
    window.location.assign(`${window.location.origin}/beta/my-profile`)

  useEffect(() => {
    window.Intercom('boot', {
      email: user?.username,
      user_id: user?.id,
      name: `${user?.first_name} ${user?.last_name}`,
      created_at: Date.now(),
    })
  }, [user?.first_name, user?.id, user?.last_name, user?.username])

  return (
    <DashboardUI
      user={user}
      publicInfo={publicInfo}
      assessments={assessments}
      profileToggler={profileToggler}
      careerGuideInfo={careerGuideInfo}
      setCareerGuideInfo={setCareerGuideInfo}
      isProfileCompleted={isProfileCompleted}
      isAssessmentsSuccess={isAssessmentsSuccess}
      isAssessModalVisible={isAssessModalVisible}
      setIsAssessModalVisible={setIsAssessModalVisible}
      assessmentAvailableForms={assessmentAvailableForms}
      isCareerGuideModalVisible={isCareerGuideModalVisible}
      setIsCareerGuideModalVisible={setIsCareerGuideModalVisible}
      phone={basicInfo?.phone ?? undefined}
      timeZoneAbr={timeZoneAbr}
    />
  )
}
