import React from 'react'
import { osha_status, UserCard } from '../../../../../../models'
import checkedIcon from '../../../../../../assets/icons/checkmarks/yellow_circle_checkmark.svg'
import dots from '../../../../../../assets/icons/other/iconDots.svg'

interface Props {
  userData: UserCard | null
}

export const ListOfPassed = ({ userData }: Props) => {
  const filterOsha = userData?.list_of_passed.osha.filter(
    status => status !== osha_status.willingToObtain_OSHA_30
  )
  const sortedOsha = filterOsha
    ?.map(oshaNumber => oshaNumber.split(' ').find(num => +num))
    .sort()

  return (
    <div className="worker-card__info-block_last">
      {/* ///TODO it will need in the next build */}
      {/* <div>
      <img
        className="worker-card__arrow-checked"
        src={false ? checkedIcon : dots}
        alt="is-checked-icon"
      />
      <p className={false ? 'worker-card__active-field' : ''}>
        Pre-screened
      </p>
    </div> */}
      {/* <div>
        <img
          className="worker-card__arrow-checked"
          src={
            userData?.list_of_passed.skillit_info.interview ? checkedIcon : dots
          }
          alt="is-checked-icon"
        />
        <p
          className={
            userData?.list_of_passed.skillit_info.interview
              ? 'worker-card__active-field'
              : ''
          }
        >
          Skillit Interviewed
        </p>
      </div> */}

      <div>
        <img
          className="worker-card__arrow-checked"
          src={
            userData?.list_of_passed.skillit_info.assessment
              ? checkedIcon
              : dots
          }
          alt="is-checked-icon"
        />
        <p
          className={
            userData?.list_of_passed.skillit_info.assessment
              ? 'worker-card__active-field'
              : ''
          }
        >
          Skillit Assessed
        </p>
      </div>

      {/* <div>
        <img
          className="worker-card__arrow-checked"
          src={
            userData?.list_of_passed.company_info.assessment
              ? checkedIcon
              : dots
          }
          alt="is-checked-icon"
        />
        <p
          className={
            userData?.list_of_passed.company_info.assessment
              ? 'worker-card__active-field'
              : ''
          }
        >
          Messer Assessed
        </p>
      </div> */}
      {userData?.list_of_passed.osha &&
      userData?.list_of_passed.osha.length > 0 ? (
        sortedOsha &&
        sortedOsha.map(statusNumber => {
          return (
            <div key={statusNumber}>
              <img
                className="worker-card__arrow-checked"
                src={checkedIcon}
                alt="is-checked-icon"
              />
              <p className="worker-card__active-field">OSHA {statusNumber}</p>
            </div>
          )
        })
      ) : (
        <div>
          <img
            className="worker-card__arrow-checked"
            src={dots}
            alt="is-checked-icon"
          />
          <p>OSHA</p>
        </div>
      )}

      {/* <div>
        <img
          className="worker-card__arrow-checked"
          src={userData?.list_of_passed.background_check ? checkedIcon : dots}
          alt="is-checked-icon"
        />
        <p
          className={
            userData?.list_of_passed.background_check
              ? 'worker-card__active-field'
              : ''
          }
        >
          BG/Drug
        </p>
      </div> */}
    </div>
  )
}
