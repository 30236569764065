import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  login,
  register,
  signOut,
  forgotPasswordRequest,
  registerOB,
  getUserStatus,
  getLoginToken,
} from '../services/auth-service'
import { ProfileCompletionInfo } from '../models/ProfileModel'
import { FACEBOOK_KEY, TokenHolder } from '../services/TokenHolder'
import Cookies from 'js-cookie'
import { LocalStorage } from '../services/localStorage'
import { qualificationResult, onboardingQualification } from './qualify-reducer'
import { getProfileCompletionInfo } from '../services/workerApi'
import { EmployerInfo, UserStatus } from '../models'
import { getBetaVersionStatus, getEmployerInfo } from '../services/employerAPI'
import { toast } from 'react-toastify'
import {
  AsyncThunkConfig,
  setWorkerInfo,
  syncObData,
  choiceListsForWorker,
  choiceListsForEmployer,
  filterOptionsForAdmin,
} from '.'
import {
  EAdminWorkersSStoreKeys,
  ECompletionStatusOB,
  initialWorkerInfoOB,
} from '../utils/constants'
import { setAuthToken } from '../services/setAuthToken'

export interface UserInfo extends ProfileCompletionInfo {
  employer_info?: EmployerInfo
}

const initialState = {
  isAuth: true,
  isEmployer: false,
  isSkillitAdmin: false,
  isWorker: false,
  user: undefined as UserInfo | undefined,
  isOnboardingApproved: false,
  obStatus: null as ECompletionStatusOB | null,
  isQualified: false,
  verificationModal: false,
  email: '',
  isBetaEnabled: false,
  isLoading: false,
}

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const syncUser: AsyncThunk<
  {
    loggedIn: boolean
    user: UserInfo | undefined
    isOnboardingApproved: boolean
    obStatus: ECompletionStatusOB | null
    isEmployer: boolean
    isSkillitAdmin: boolean
    isBetaEnabled: boolean
    isWorker: boolean
  },
  void,
  AsyncThunkConfig
> = createAsyncThunk('AUTH/SYNC-USER', async (_, thunkAPI) => {
  const userToken = TokenHolder.get()
  const logoutedUser = {
    loggedIn: false,
    user: undefined,
    isOnboardingApproved: false,
    obStatus: null,
    isEmployer: false,
    isSkillitAdmin: false,
    isBetaEnabled: false,
    isWorker: false,
  }
  if (userToken !== undefined) {
    const user: UserInfo | undefined = await getProfileCompletionInfo(
      currentTimezone
    ).catch(() => {
      TokenHolder.remove()
      return undefined
    })

    const userStatus: UserStatus = await getUserStatus()
      .then(res => res)
      .catch(err => err)

    const isSkillitAdmin = userStatus['permission-group'] === 'skillit_admin'
    const isWorker = userStatus['permission-group'] === 'worker'
    const isEmployer: boolean =
      (userStatus['permission-group'] === 'employer' || isSkillitAdmin) &&
      !sessionStorage.getItem(EAdminWorkersSStoreKeys.isAdmin)

    const obStatus: ECompletionStatusOB | null =
      userStatus.details?.status ?? null
    const isOnboardingApproved: boolean =
      isEmployer || isSkillitAdmin || !obStatus
        ? false
        : obStatus === ECompletionStatusOB.approved ||
          obStatus === ECompletionStatusOB.verified

    const isBetaEnabled =
      isEmployer && (await getBetaVersionStatus())?.allow_beta_version

    if (!isBetaEnabled) {
      Cookies.remove('betaVersion')
    }

    if (!isEmployer && !isSkillitAdmin) {
      await thunkAPI.dispatch(choiceListsForWorker())
      await thunkAPI.dispatch(syncObData())
    }
    const loggedIn = user !== undefined
    if ((isEmployer || isSkillitAdmin) && user) {
      await thunkAPI.dispatch(choiceListsForEmployer())
      const companyInfo: EmployerInfo | null = await getEmployerInfo().catch(
        err => {
          toast.error(err.message)
          return null
        }
      )
      if (!companyInfo) return logoutedUser
      user.employer_info = companyInfo
    }
    if (isSkillitAdmin && user) {
      await thunkAPI.dispatch(filterOptionsForAdmin())
    }

    return {
      loggedIn: loggedIn,
      user: user,
      isOnboardingApproved: isOnboardingApproved,
      obStatus: obStatus,
      isEmployer: isEmployer,
      isWorker: isWorker,
      isSkillitAdmin: isSkillitAdmin,
      isBetaEnabled: isBetaEnabled,
    }
  }
  return logoutedUser
})

export const signUp: AsyncThunk<
  void,
  {
    email: string
    password: string
  },
  AsyncThunkConfig
> = createAsyncThunk(
  'AUTH/SIGN-UP',
  async (
    { email, password }: { email: string; password: string },
    thunkAPI
  ) => {
    if (!emailIsValid(email)) {
      throw new Error('Please enter a valid email')
    }
    LocalStorage.setItemForKey(true, 'flex_profile')
    await register(email, password)
    await thunkAPI.dispatch(
      signIn({ email: email, password: password, rememberMe: false })
    )
  }
)

export const signUpOB: AsyncThunk<
  void,
  {
    email: string
    phone: string
    first_name: string
    last_name: string
    source?: string
  },
  AsyncThunkConfig
> = createAsyncThunk(
  'AUTH/SIGN-UP-OB',
  async (
    {
      email,
      phone,
      first_name,
      last_name,
      source,
    }: {
      email: string
      phone: string
      first_name: string
      last_name: string
      source?: string
    },
    thunkAPI
  ) => {
    LocalStorage.setItemForKey(true, 'flex_profile')
    const token = await registerOB(email, phone, first_name, last_name, source)

    TokenHolder.set(token.token, true)
    LocalStorage.setItemForKey(true, 'wasRegistred')
    await thunkAPI.dispatch(syncUser())
  }
)

export const signIn: AsyncThunk<
  void,
  {
    email: string
    password: string
    rememberMe: boolean
  },
  AsyncThunkConfig
> = createAsyncThunk(
  'AUTH/SIGN-IN',
  async (
    {
      email,
      password,
      rememberMe,
    }: { email: string; password: string; rememberMe: boolean },
    thunkAPI
  ) => {
    if (!emailIsValid(email)) {
      throw new Error('Please enter a valid email')
    }

    const response = await login(email, password)
    TokenHolder.set(response.token, rememberMe)
    LocalStorage.setItemForKey(true, 'wasRegistred')

    thunkAPI.dispatch(syncUser())
  }
)

export const logout: AsyncThunk<void, void | (() => void), {}> =
  createAsyncThunk(
    'AUTH/LOGOUT',
    async (action: void | (() => void), thunkAPI) => {
      const token = TokenHolder.get()
      if (token) {
        thunkAPI.dispatch(setWorkerInfo(initialWorkerInfoOB))
        signOut(token)
          .then(res => {
            TokenHolder.remove()
            Cookies.remove(FACEBOOK_KEY)
            localStorage.clear()
            sessionStorage.clear()
            window.location.assign('https://skillit.com')
            return res
          })
          .then(_ => action && action())
      }
    }
  )

export const forgotPassword = createAsyncThunk(
  'AUTH/FORGOT-PASSWORD',
  async (email: string, thunkAPI) => {
    return forgotPasswordRequest(email)
  }
)

export const emailLogin: AsyncThunk<
  void,
  {
    oneTimeToken: string
  },
  AsyncThunkConfig
> = createAsyncThunk(
  'AUTH/SIGN-IN',
  async ({ oneTimeToken }: { oneTimeToken: string }, thunkAPI) => {
    try {
      const { token } = await getLoginToken(oneTimeToken)
      setAuthToken(token, true)

      thunkAPI.dispatch(syncUser())
    } catch (error) {
      const isLogIn = Cookies.get('token')
      !isLogIn && toast.error('This link has expired, please log in again')
    }
  }
)

export const profileCompletionInfo = createAsyncThunk(
  'AUTH/PROFILE-INFO',
  async () => {
    const user = await getProfileCompletionInfo(currentTimezone)
    return { user: user }
  }
)

const emailIsValid = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}

const authSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    setVerificattionModal: (state, action) => {
      state.verificationModal = action.payload
    },
    setUserEmail: (state, action) => {
      state.email = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setStatus: (state, action) => {
      state.obStatus = action.payload
    },
    setIsEmployer: (state, action) => {
      state.isEmployer = action.payload
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload
    },
    setIsSkillitAdmin: (state, action) => {
      state.isSkillitAdmin = action.payload
    },
    setIsOnboardingApproved: (state, action) => {
      state.isOnboardingApproved = action.payload
    },
    setIsBetaEnabled: (state, action) => {
      state.isBetaEnabled = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(syncUser.pending, state => {
      state.isLoading = true
    })
    builder.addCase(syncUser.fulfilled, (state, action) => {
      state.isAuth = action.payload.loggedIn
      state.isOnboardingApproved = action.payload.isOnboardingApproved
      state.obStatus = action.payload.obStatus
      state.user = action.payload.user
      state.isEmployer = action.payload.isEmployer
      state.isSkillitAdmin = action.payload.isSkillitAdmin
      state.isBetaEnabled = action.payload.isBetaEnabled
      state.isWorker = action.payload.isWorker
      state.isLoading = false
    })
    builder.addCase(syncUser.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(qualificationResult.fulfilled, (state, action) => {
      state.isQualified = action.payload.isQualified
    })
    builder.addCase(onboardingQualification.fulfilled, (state, action) => {
      state.isQualified = action.payload.isQualified
    })
    builder.addCase(profileCompletionInfo.fulfilled, (state, action) => {
      state.user = action.payload.user
    })
  },
})

export const authReducer = authSlice.reducer
export const {
  setVerificattionModal,
  setUserEmail,
  setUser,
  setStatus,
  setIsEmployer,
  setIsAuth,
  setIsOnboardingApproved,
  setIsSkillitAdmin,
  setIsBetaEnabled,
} = authSlice.actions
