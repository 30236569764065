import React, { Fragment, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import infoIcon from '../../../../assets/icons/other/info_2.svg'
import {
  AssessmentFilterParams,
  FilterItem,
  SkillsType,
  WorkersFilter,
} from '../../../../models/Employer'
import {
  defaultFilter,
  EFilterTypes,
  setEnteredZipCode,
  setFilters,
  setFilterType,
} from '../../../../redux/employer-reducer'
import { useAppDispatch } from '../../../../redux/store'
// import { ETravelDistance, travel_distance } from '../../../../utils/constants'
import { useTypedSelector } from '../../../../utils/hooks'
import {
  ButtonAsText,
  Checkbox,
  HidingFilter,
  Prompt,
  Slider,
} from '../../../atoms'
import './employeeFilter.scss'

export const EmployeeFilter = () => {
  const dispatch = useAppDispatch()
  const filterParams = useTypedSelector(s => s.variables.choiceListForEmployer)
  const companyName = useTypedSelector(s => s.auth.user)?.employer_info
    ?.company_info.name
  const enteredZipCode = useTypedSelector(s => s.employer.enteredZipcode)
  const filters = useTypedSelector(state => state.employer.filters)
  const assessmentFilters = filterParams?.assessment
  const skills = filterParams?.skills
  const roles = filterParams?.roles
  const languages = filterParams?.languages
  const locations = filterParams?.location
  // const interviewFilterParams = filterParams?.interview

  const [isRangeOpened, setIsRangeOpened] = useState<boolean>(false)
  const isLocationSelected: boolean = useMemo(
    () =>
      !!locations?.find(
        location => location.zip_code === filters.location?.[0]
      ),
    [filters, locations]
  )

  const prepareQualificationsFilters = () => {
    const result: FilterItem[] = []

    if (filterParams?.qualifications) {
      filterParams.qualifications.forEach(el => {
        result.push(...el.certifications)
      })
    }

    return result
  }

  const qualificationsFiltersList = prepareQualificationsFilters()

  const checkboxHandler = (
    isChecked: boolean,
    prevState: WorkersFilter,
    key: 'osha_status' | 'training_certifications' | 'language',
    names: FilterItem[]
  ) => {
    let filteredDistance = !isChecked
      ? [...(prevState[key] ?? []), ...names]
      : prevState[key]?.filter(
          el => !names.map(name => name.id).includes(el.id)
        ) ?? null
    if (filteredDistance && !filteredDistance.length) return null
    return filteredDistance
  }

  const skillsCheckboxHandler = (
    isChecked: boolean,
    prevState: WorkersFilter,
    names: SkillsType
  ) => {
    let filteredList = !isChecked
      ? [...(prevState.skills ?? []), names]
      : prevState.skills?.filter(
          el => !names.value.choice.includes(el.value.choice)
        ) ?? null
    if (filteredList && !filteredList.length) filteredList = null
    return filteredList
  }

  const handlingFilterType = (e: React.SyntheticEvent) => {
    if (e.target) {
      dispatch(setFilterType(EFilterTypes.filterType))
    }
  }

  // const setFilterInterviewScore = (initValue: string, companyId: number) => {
  //   dispatch(
  //     setFilters({
  //       ...filters,
  //       interview_score:
  //         initValue === '0'
  //           ? null
  //           : [
  //               {
  //                 company_id: companyId,
  //                 value: initValue,
  //               },
  //             ],
  //       page_number: initValue === '0' ? filters.page_number : 0,
  //     })
  //   )
  // }

  const setFilterAssessmentScore = (
    initValue: string,
    initFilter: AssessmentFilterParams
  ) => {
    dispatch(
      setFilters(
        initValue === '0'
          ? {
              ...filters,
              assessment_score: filters.assessment_score
                ? [
                    ...filters.assessment_score.filter(
                      el => el.id !== initFilter.id
                    ),
                  ]
                : null,
            }
          : {
              ...filters,
              assessment_score: filters.assessment_score
                ? [
                    ...filters.assessment_score.filter(
                      el => el.id !== initFilter.id
                    ),
                    {
                      id: initFilter.id,
                      value: initValue,
                    },
                  ]
                : [
                    {
                      id: initFilter.id,
                      value: initValue,
                    },
                  ],
              page_number: 0,
            }
      )
    )
  }

  return (
    <div
      onClick={e => handlingFilterType(e)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handlingFilterType(e)
        }
      }}
      tabIndex={0}
      className="employee-filter"
    >
      <div className="employee-filter__header">
        <h4 className="employee-filter__title">Filter</h4>
        <button
          className="employee-filter__clear-btn"
          onClick={() => {
            dispatch(setFilters({ ...defaultFilter }))
            setIsRangeOpened(false)
          }}
        >
          Clear
        </button>
      </div>
      <div className="employee-filter__wrapper">
        <ul className="employee-filter__checkbox-list">
          <li className="employee-filter__checkbox-item">
            <Checkbox
              name="Past or Present Employee"
              idCheckbox={+uuidv4()}
              checked={
                filters.work_experience === companyName ? true : undefined
              }
              toggleCheckbox={(_, isChecked) => {
                dispatch(
                  setFilters({
                    ...filters,
                    work_experience: isChecked ? null : companyName ?? '',
                    page_number: 0,
                  })
                )
              }}
            />
          </li>
          <li className="employee-filter__checkbox-item">
            <Checkbox
              name="Profile Photo"
              idCheckbox={+uuidv4()}
              checked={filters.profile_picture_field ? true : undefined}
              toggleCheckbox={(_, isChecked) =>
                dispatch(
                  setFilters({
                    ...filters,
                    profile_picture_field: !isChecked ? true : null,
                    page_number: 0,
                  })
                )
              }
            />
          </li>
          <li className="employee-filter__checkbox-item">
            <Checkbox
              name="Union"
              idCheckbox={+uuidv4()}
              checked={filters.unions ? true : undefined}
              toggleCheckbox={(_, isChecked) =>
                dispatch(
                  setFilters({
                    ...filters,
                    unions: !isChecked ? true : null,
                    page_number: 0,
                  })
                )
              }
            />
          </li>
          <li className="employee-filter__checkbox-item">
            <Checkbox
              name="Authorized to work in the U.S."
              idCheckbox={+uuidv4()}
              checked={filters.eligible_work_us ? true : undefined}
              toggleCheckbox={(_, isChecked) =>
                dispatch(
                  setFilters({
                    ...filters,
                    eligible_work_us: !isChecked ? true : null,
                    page_number: 0,
                  })
                )
              }
            />
          </li>
        </ul>

        <HidingFilter
          className="employee-filter__section"
          filterTitle="Role"
          opened={filters.role?.length ? true : undefined}
          dynamicList
        >
          <ul className="employee-filter__checkbox-list">
            {roles?.map((el, i) => {
              const filteredRoles = filters.role?.filter(
                selected => selected.id !== el.id
              )

              return (
                <li key={i} className="employee-filter__checkbox-item">
                  <Checkbox
                    name={el.choice}
                    idCheckbox={i}
                    checked={
                      filters?.role?.find(selected => selected.id === el.id)
                        ? true
                        : undefined
                    }
                    toggleCheckbox={(_, isChecked) => {
                      dispatch(
                        setFilters({
                          ...filters,
                          role: isChecked
                            ? filteredRoles?.length
                              ? filteredRoles
                              : null
                            : filters.role?.length
                            ? [...filters.role, el]
                            : [el],
                          page_number: 0,
                        })
                      )
                    }}
                  />
                </li>
              )
            })}
          </ul>
        </HidingFilter>

        <HidingFilter
          className="employee-filter__section"
          filterTitle="Skills"
          opened={filters.skills ? true : undefined}
          dynamicList
        >
          {skills && (
            <ButtonAsText
              onClick={() =>
                dispatch(
                  setFilters({
                    ...filters,
                    skills:
                      skills.length === filters.skills?.length
                        ? null
                        : skills.map(skill => ({
                            exact: true,
                            value: skill,
                          })),
                    page_number: 0,
                  })
                )
              }
            >
              {skills.length === filters.skills?.length
                ? 'Clear All'
                : 'Select All'}
            </ButtonAsText>
          )}
          <ul className="employee-filter__checkbox-list">
            {skills &&
              skills.map(skill => (
                <li
                  key={skill.choice}
                  className="employee-filter__checkbox-item"
                >
                  <Checkbox
                    name={skill.choice}
                    idCheckbox={+uuidv4()}
                    checked={
                      filters.skills?.find(el => el.value.id === skill.id)
                        ? true
                        : undefined
                    }
                    toggleCheckbox={(_, isChecked) => {
                      dispatch(
                        setFilters({
                          ...filters,
                          skills: skillsCheckboxHandler(isChecked, filters, {
                            exact: true,
                            value: skill,
                          }),
                          page_number: 0,
                        })
                      )
                    }}
                  />
                </li>
              ))}
          </ul>
        </HidingFilter>

        {/* <HidingFilter
          className="employee-filter__section"
          filterTitle="Travel distance"
          opened={filters.travel_distance_interval ? true : undefined}
        >
          <ul className="employee-filter__checkbox-list">
            <li className="employee-filter__checkbox-item">
              <Checkbox
                name={travel_distance.upToFiveMiles.name}
                idCheckbox={+uuidv4()}
                checked={
                  filters.travel_distance_interval?.includes(
                    travel_distance.upToFiveMiles.value
                  )
                    ? true
                    : undefined
                }
                toggleCheckbox={(_, isChecked) =>
                  dispatch(
                    setFilters({
                      ...filters,
                      travel_distance_interval: travelDistanceHandler(
                        isChecked,
                        filters,
                        'travel_distance_interval',
                        travel_distance.upToFiveMiles.value
                      ),
                      page_number: 0,
                    })
                  )
                }
              />
            </li>
            <li className="employee-filter__checkbox-item">
              <Checkbox
                name={travel_distance.upToTenMiles.name}
                idCheckbox={+uuidv4()}
                checked={
                  filters.travel_distance_interval?.includes(
                    travel_distance.upToTenMiles.value
                  )
                    ? true
                    : undefined
                }
                toggleCheckbox={(_, isChecked) =>
                  dispatch(
                    setFilters({
                      ...filters,
                      travel_distance_interval: travelDistanceHandler(
                        isChecked,
                        filters,
                        'travel_distance_interval',
                        travel_distance.upToTenMiles.value
                      ),
                      page_number: 0,
                    })
                  )
                }
              />
            </li>
            <li className="employee-filter__checkbox-item">
              <Checkbox
                name={travel_distance.upTofifteenMiles.name}
                idCheckbox={+uuidv4()}
                checked={
                  filters.travel_distance_interval?.includes(
                    travel_distance.upTofifteenMiles.value
                  )
                    ? true
                    : undefined
                }
                toggleCheckbox={(_, isChecked) =>
                  dispatch(
                    setFilters({
                      ...filters,
                      travel_distance_interval: travelDistanceHandler(
                        isChecked,
                        filters,
                        'travel_distance_interval',
                        travel_distance.upTofifteenMiles.value
                      ),
                      page_number: 0,
                    })
                  )
                }
              />
            </li>
            <li className="employee-filter__checkbox-item">
              <Checkbox
                name={travel_distance.upTo25Miles.name}
                idCheckbox={+uuidv4()}
                checked={
                  filters.travel_distance_interval?.includes(
                    travel_distance.upTo25Miles.value
                  )
                    ? true
                    : undefined
                }
                toggleCheckbox={(_, isChecked) =>
                  dispatch(
                    setFilters({
                      ...filters,
                      travel_distance_interval: travelDistanceHandler(
                        isChecked,
                        filters,
                        'travel_distance_interval',
                        travel_distance.upTo25Miles.value
                      ),
                      page_number: 0,
                    })
                  )
                }
              />
            </li>
            <li className="employee-filter__checkbox-item">
              <Checkbox
                name={travel_distance.over25Miles.name}
                idCheckbox={+uuidv4()}
                checked={
                  filters.travel_distance_interval?.includes(
                    travel_distance.over25Miles.value
                  )
                    ? true
                    : undefined
                }
                toggleCheckbox={(_, isChecked) =>
                  dispatch(
                    setFilters({
                      ...filters,
                      travel_distance_interval: travelDistanceHandler(
                        isChecked,
                        filters,
                        'travel_distance_interval',
                        travel_distance.over25Miles.value
                      ),
                      page_number: 0,
                    })
                  )
                }
              />
            </li>
          </ul>
        </HidingFilter> */}

        <HidingFilter
          className="employee-filter__section"
          filterTitle="Location"
          opened={filters.location?.length ? true : undefined}
          dynamicList
        >
          <ul className="employee-filter__checkbox-list">
            <li
              className="employee-filter__checkbox-item"
              style={{ opacity: isLocationSelected ? '1' : '0.4' }}
            >
              <Checkbox
                name="Will relocate to"
                idCheckbox={+uuidv4()}
                checked={
                  isLocationSelected ? filters.relocate || undefined : undefined
                }
                toggleCheckbox={(_, isChecked) => {
                  dispatch(
                    setFilters({
                      ...filters,
                      relocate: isLocationSelected ? !isChecked : null,
                      page_number: 0,
                    })
                  )
                }}
                disabled={!isLocationSelected || undefined}
              />
            </li>
            {locations?.map((el, i) => {
              const filteredLocations = filters.location?.filter(
                selected => selected !== el.zip_code
              )

              return (
                <li key={el.city} className="employee-filter__checkbox-item">
                  <Checkbox
                    className="employee-filter__checkbox-item_radio"
                    name={el.name}
                    idCheckbox={i}
                    checked={
                      filters.location?.find(
                        selected => selected === el.zip_code
                      )
                        ? true
                        : undefined
                    }
                    toggleCheckbox={(_, isChecked) => {
                      isChecked &&
                        el.zip_code === enteredZipCode &&
                        dispatch(setEnteredZipCode(null))

                      dispatch(
                        setFilters({
                          ...filters,
                          location: isChecked
                            ? filteredLocations?.length
                              ? filteredLocations
                              : null
                            : [el.zip_code],
                          page_number: 0,
                        })
                      )
                    }}
                  />
                </li>
              )
            })}
          </ul>
        </HidingFilter>

        {/* 
        TODO:Temporarily commented at the request of the customer
        <HidingFilter   
          className="employee-filter__section"
          opened={filters.interview_score?.length ? true : undefined}
          filterTitle={
            <>
              Skillit Interview
              <Prompt
                text={`Filter by how the candidate performed in a Skillit interview`}
                className="employee-filter__section-prompt"
              >
                <img src={infoIcon} alt="info" />
              </Prompt>
            </>
          }
        >
          <Slider
            key={interviewFilterParams?.skillit_company_id}
            isRangeOpened={isRangeOpened}
            defaultValue={
              filters.interview_score?.find(
                score =>
                  score.company_id === interviewFilterParams?.skillit_company_id
              )?.value
            }
            sliderTitle={''}
            rangeProcessing={(value: string) => {
              interviewFilterParams?.skillit_company_id &&
                setFilterInterviewScore(
                  value,
                  interviewFilterParams?.skillit_company_id
                )
            }}
          />
        </HidingFilter> */}

        <HidingFilter
          className="employee-filter__section"
          opened={filters.assessment_score?.length ? true : undefined}
          dynamicList
          filterTitle={
            <>
              Assessment Score{' '}
              <Prompt
                text={`Filter by how the candidate performed in a Skillit skills assessment`}
                className="employee-filter__section-prompt"
              >
                <img src={infoIcon} alt="info" />
              </Prompt>
            </>
          }
        >
          {assessmentFilters &&
            assessmentFilters.map(filter => {
              const defaultData = filters.assessment_score?.find(score => {
                return score.id === filter.id
              })

              return (
                <Slider
                  key={filter.key}
                  isRangeOpened={isRangeOpened}
                  defaultValue={defaultData?.value}
                  sliderTitle={filter.name}
                  rangeProcessing={(value: string) => {
                    if (value) {
                      setFilterAssessmentScore(value, filter)
                    }
                  }}
                />
              )
            })}
        </HidingFilter>

        <HidingFilter
          className="employee-filter__section"
          filterTitle="Experience"
          opened={filters.experience_years ? true : undefined}
        >
          <ul className="employee-filter__checkbox-list">
            <li className="employee-filter__checkbox-item">
              <Checkbox
                name="0–4 years"
                idCheckbox={+uuidv4()}
                checked={
                  filters.experience_years?.includes(0) ? true : undefined
                }
                toggleCheckbox={(_, isChecked) =>
                  dispatch(
                    setFilters({
                      ...filters,
                      experience_years: !isChecked
                        ? [...(filters.experience_years ?? []), 0]
                        : filters.experience_years?.filter(
                            experience => experience !== 0
                          ) ?? null,
                      page_number: 0,
                    })
                  )
                }
              />
            </li>
            <li className="employee-filter__checkbox-item">
              <Checkbox
                name="5-10 years"
                idCheckbox={+uuidv4()}
                checked={
                  filters.experience_years?.includes(1) ? true : undefined
                }
                toggleCheckbox={(_, isChecked) =>
                  dispatch(
                    setFilters({
                      ...filters,
                      experience_years: !isChecked
                        ? [...(filters.experience_years ?? []), 1]
                        : filters.experience_years?.filter(
                            experience => experience !== 1
                          ) ?? null,
                      page_number: 0,
                    })
                  )
                }
              />
            </li>
            <li className="employee-filter__checkbox-item">
              <Checkbox
                name="10+ years"
                idCheckbox={+uuidv4()}
                checked={
                  filters.experience_years?.includes(2) ? true : undefined
                }
                toggleCheckbox={(_, isChecked) =>
                  dispatch(
                    setFilters({
                      ...filters,
                      experience_years: !isChecked
                        ? [...(filters.experience_years ?? []), 2]
                        : filters.experience_years?.filter(
                            experience => experience !== 2
                          ) ?? null,
                      page_number: 0,
                    })
                  )
                }
              />
            </li>
          </ul>
        </HidingFilter>

        <HidingFilter
          className="employee-filter__section"
          filterTitle="Qualifications &amp; Certifications"
          opened={filters.training_certifications ? true : undefined}
          dynamicList
        >
          {qualificationsFiltersList && (
            <ButtonAsText
              onClick={() =>
                dispatch(
                  setFilters({
                    ...filters,
                    training_certifications:
                      qualificationsFiltersList.length ===
                      filters.training_certifications?.length
                        ? null
                        : qualificationsFiltersList,
                    page_number: 0,
                  })
                )
              }
            >
              {qualificationsFiltersList.length ===
              filters.training_certifications?.length
                ? 'Clear All'
                : 'Select All'}
            </ButtonAsText>
          )}
          <ul className="employee-filter__checkbox-list">
            {qualificationsFiltersList &&
              filterParams.qualifications?.map(group => {
                return (
                  <Fragment key={group.group}>
                    <div className="employee-filter__checkbox-title">
                      {group.group}
                    </div>
                    {group.certifications.map(certificate => {
                      return (
                        <li
                          key={certificate.choice}
                          className="employee-filter__checkbox-item"
                        >
                          <Checkbox
                            name={certificate.choice}
                            idCheckbox={+uuidv4()}
                            checked={
                              filters.training_certifications
                                ?.map(
                                  filterQualification => filterQualification.id
                                )
                                .includes(certificate.id)
                                ? true
                                : undefined
                            }
                            toggleCheckbox={(_, isChecked) =>
                              dispatch(
                                setFilters({
                                  ...filters,
                                  training_certifications: checkboxHandler(
                                    isChecked,
                                    filters,
                                    'training_certifications',
                                    [certificate]
                                  ),
                                  page_number: 0,
                                })
                              )
                            }
                          />
                        </li>
                      )
                    })}
                  </Fragment>
                )
              })}
          </ul>
        </HidingFilter>

        <HidingFilter
          className="employee-filter__section"
          filterTitle="Languages"
          opened={filters.training_certifications ? true : undefined}
          dynamicList
        >
          {languages && (
            <ButtonAsText
              onClick={() =>
                dispatch(
                  setFilters({
                    ...filters,
                    language:
                      languages.length === filters.language?.length
                        ? null
                        : languages,
                    page_number: 0,
                  })
                )
              }
            >
              {languages.length === filters.language?.length
                ? 'Clear All'
                : 'Select All'}
            </ButtonAsText>
          )}
          <ul className="employee-filter__checkbox-list">
            {languages &&
              languages?.map(language => {
                return (
                  <li
                    key={language.choice}
                    className="employee-filter__checkbox-item"
                  >
                    <Checkbox
                      name={language.choice}
                      idCheckbox={+uuidv4()}
                      checked={
                        filters.language
                          ?.map(lang => lang.id)
                          .includes(language.id)
                          ? true
                          : undefined
                      }
                      toggleCheckbox={(_, isChecked) =>
                        dispatch(
                          setFilters({
                            ...filters,
                            language: checkboxHandler(
                              isChecked,
                              filters,
                              'language',
                              [language]
                            ),
                            page_number: 0,
                          })
                        )
                      }
                    />
                  </li>
                )
              })}
          </ul>
        </HidingFilter>

        <HidingFilter
          className="employee-filter__section"
          opened={filters.desired_pay_rate_max ? true : undefined}
          filterTitle="Minimum Desired Pay"
        >
          <Slider
            isRangeOpened={isRangeOpened}
            defaultValue={filters.desired_pay_rate_max ?? undefined}
            sliderTitle=""
            rangeProcessing={(value: string) => {
              dispatch(
                setFilters({
                  ...filters,
                  desired_pay_rate_max: value === '0' ? null : value,
                  page_number: 0,
                })
              )
            }}
            minValue={'0'}
          />
        </HidingFilter>
      </div>
    </div>
  )
}
